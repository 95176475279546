import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import music from "../assets/audio/u-said-it-v13-1167.mp3";

const Box = styled.div`
  display: flex;
  cursor: pointer;
  position: fixed;
  left: 8rem;
  top: 3rem;
  z-index: 10;

  & > *:nth-child(1) {
    animation-delay: 0.2s;
  }
  & > *:nth-child(2) {
    animation-delay: 0.3s;
  }
  & > *:nth-child(3) {
    animation-delay: 0.4s;
  }
  & > *:nth-child(4) {
    animation-delay: 0.5s;
  }
  & > *:nth-child(5) {
    animation-delay: 0.8s;
  }
`;

const play = keyframes`
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
  100% {
    transform: scaleY(1);
  }
`;

const Line = styled.span`
  background: ${props => props.theme.text};
  border: 1px solid ${props => props.theme.body};
  animation: ${play} 1s ease infinite;
  animation-play-state: ${props => (props.click ? "running" : "paused")};
  height: 1rem;
  width: 2px;
  margin: 0 0.1rem;
`;

const SoundBar = () => {
  const ref = useRef(null);
  const [click, setClick] = useState(false);
  const [audioPlayed, setAudioPlayed] = useState(false);

  const handleClick = () => {
    if (!audioPlayed) {
      // Play audio for the first time and start the animation
      ref.current.play();
      setAudioPlayed(true);
      setClick(true); // Start the animation on first play
    } else {
      // Toggle audio on subsequent clicks
      setClick(prevClick => !prevClick);
      if (click) {
        ref.current.pause();
      } else {
        ref.current.play();
      }
    }
  };

  // Effect to handle first click anywhere on the document
  useEffect(() => {
    const handleFirstClick = () => {
      if (!audioPlayed) {
        ref.current.play();
        setAudioPlayed(true);
        setClick(true); // Start the animation on first play
      }
      // Remove the event listener after the first click
      document.removeEventListener('click', handleFirstClick);
    };

    document.addEventListener('click', handleFirstClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleFirstClick);
    };
  }, [audioPlayed]);

  return (
    <Box onClick={handleClick}>
      <Line click={click} />
      <Line click={click} />
      <Line click={click} />
      <Line click={click} />
      <Line click={click} />
      <audio src={music} ref={ref} loop />
    </Box>
  );
};

export default SoundBar;
